import { Injectable } from '@angular/core';
import { getEnvironment } from '../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsProvider {

  private static initialized = false;

  private static api_url: string;

  private static endpoints: any;

  constructor() {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
  }

  private static initialize() {
    const environment = getEnvironment();
    SettingsProvider.api_url = environment.apiUrl;
    SettingsProvider.endpoints = {
      authExternal: 'api/auth/social-media',
      login: 'api/auth/sign-in',
      register: 'api/auth/sign-up',
      logout: 'api/logout',
    };
    SettingsProvider.initialized = true;
  }

  static authRegister(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.register;
  }

  static authLogin(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.login;
  }

  static authExternal(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.authExternal;
  }

  static authLogout(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + SettingsProvider.endpoints.logout;
  }

  static getAccount(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + 'api/account';
  }

  static getCart(): string {
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + 'api/get-cart';
  }

  static getUrl(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + 'api/' + url;
  }

  static url(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return SettingsProvider.api_url + url;
  }

  static getCustomUrl(url:string): string{
    if (!SettingsProvider.initialized) {
      SettingsProvider.initialize();
    }
    return url;
  }

}
