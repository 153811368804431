import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime',
    standalone: true,
})
export class FormatTimePipe implements PipeTransform {
    
    transform(value: any): string {
        if (!value || value == '00:00:00') return '0s';
        
        const time = value.split(':');
        const hours = +time[0];
        const minutes = +time[1];
        const seconds = +time[2];
        
        let formattedTime = '';
        if (hours > 0) {
            formattedTime += `${hours}h `;
        }
        if (minutes > 0) {
            formattedTime += `${minutes}min `;
        }
        if (seconds > 0) {
            formattedTime += `${seconds}s`;
        }
        
        return formattedTime.trim();
    }
    
}