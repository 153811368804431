import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CountdownService {
    private countdownTimeSource = new BehaviorSubject('00:00:00');
    countdownTime$ = this.countdownTimeSource.asObservable();
    private countdownSubscription?: Subscription;
    
    startCountdown(time: string) {
        this.countdownTimeSource.next(time);
        
        if (this.countdownSubscription) {
            this.countdownSubscription.unsubscribe();
        }
        
        this.countdownSubscription = interval(1000).pipe(
            map(() => {
                let [hours, minutes, seconds] = this.countdownTimeSource.value.split(':').map(Number);
                seconds--;
                if (seconds < 0) {
                    seconds = 59;
                    minutes--;
                    if (minutes < 0) {
                        minutes = 59;
                        hours--;
                    }
                }
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            }),
            takeWhile(time => time !== '00:00:00', true)
            ).subscribe(time => this.countdownTimeSource.next(time));
    }
}