import { Injectable } from '@angular/core';
import { CartUserModel } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { HttpClientProvider } from './http-client';
import { getEnvironment } from '../environment/environment';
import { AuthService } from './auth.service';

export type CartType = CartUserModel | null;

@Injectable({
	providedIn: 'root'
})
export class CartService {

	env = getEnvironment();

	private _cart = new BehaviorSubject<CartType>(null);
	cart$ = this._cart.asObservable();

	get currentCart(): CartType {
		return this._cart.value;
	}
	set currentCart(cart: CartType) {
		this._cart.next(cart);
	}

	headers:any = null;
	
	constructor(
		private http: HttpClientProvider,
		private authService: AuthService,
	) {
	}

	getCart(): CartType {
		return this.currentCart ?? null;
	}

	getUserCart(): Observable<CartType> {
		const auth = this.authService.getAuth();
		if (!auth || !auth.access_token) {
			return of(null);
		}
		return this._getUserCart().pipe(
			map((data: any) => {
				console.log(data);
				let itemData:CartType = null;
				if(data['status'] && data['data'] ) {
					itemData = {
						cart: data['data'] ?? undefined,
						totalQuantity: data['data']['total_quantity'] ? parseInt(data['data']['total_quantity']) : 0,
					};
				}
				return this.cartData(itemData);
			})
		);
	}

	private _getUserCart(): Observable<CartUserModel> {
		return this.http.getRequest(SettingsProvider.getCart());
	}

	cartData(user: CartType) {
		if (user) {
		  	this.currentCart = user;
		} else {
		  	null;
		}
		return user;
	}
}
